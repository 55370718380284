.btn {
  font-weight: 600;
  border: 0px solid #d8d8d8;
  transition: 400ms;
  border-radius: 8px;
  box-shadow: none !important;
  transition: 100ms;
}

.btn-group {
  display: flex;
  flex-direction: column;
}
.btn-group button {
  min-height: 50px;
  margin-right: 0;
  margin-bottom: 10px;

  padding: 8px 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: none;
  border-radius: 5px !important;
  flex: 1;
}
.btn.active {
  background: #349ff5;
  color: white;
}
.btn.active .fa {
  line-height: 17px;
  color: #ffffff;
  padding: 2px 6px;
  border-radius: 100%;

  font-size: 10px;
}
.btn.btn-sm {
  min-height: 30px;
  padding: 4px 8px;
  margin-bottom: 0;
}

.btn-default {
  background: #f3f3f3;
}
.btn-outline-primary {
  background: white;
}
