$b: #fff;
$inner: -75px;
$innerplus: 75px;
$outer: -130px;
$outerplus: 130px;

.main-loader {
  transform: scale(0.08);
}
.circle {
  width: 325px;
  height: 325px;
  display: block;
  background: #0e6bb0;
  border-radius: 500%;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .inner {
    width: 200px;
    height: 200px;
    background: $b;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 100%;
    box-shadow: 0 $outer 0 $inner $b;
    animation: switch 8s ease-in-out infinite;
    @keyframes switch {
      0% {
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 200px;
        height: 200px;
        box-shadow: 0 $outer 0 $inner $b;
      }
      25% {
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 200px;
        height: 200px;
        box-shadow: 0 $outer 0 $inner $b;
      }
      50% {
        top: calc(100% - 55px);
        width: 50px;
        height: 50px;
        box-shadow: 0 $outer 0 75px $b;
        transform: translateX(-50%) translateY(0);
      }
      75% {
        top: calc(100% - 55px);
        width: 50px;
        height: 50px;
        box-shadow: 0 $outer 0 75px $b;
        transform: translateX(-50%) translateY(0);
      }
      100% {
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 200px;
        height: 200px;
        box-shadow: 0 $outer 0 $inner $b;
      }
    }
  }
}
