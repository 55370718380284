.card {
  overflow: hidden;
}
.progress_text {
  font-size: 8px;
  color: #4c4c4c;
  position: absolute;
  opacity: 1;
  transition: opacity 1000ms ease-out;
  mix-blend-mode: difference;
  width: 100%;
  text-align: right;
  height: 10px;
  line-height: 10px;
  border-radius: 10px;
  top: 6px;
}
.overlay_loading {
  position: absolute;
  z-index: 999;
  height: 104%;
  width: 104%;
  left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffb8;
}
.card-header {
  background-color: map-get($theme-colors, "primary");
  text-align: center;
  color: white;
}
.breadcrumb-custom {
  background: #299af3;
}
.breadcrumb-custom .col {
  height: 25px;
}
.breadcrumb-custom .col span {
  color: white;
  position: absolute;
  line-height: 25px;
  left: 3%;
}
.breadcrumb-custom .col:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #aad2f3;
  border-right: 13px solid #c0e3ff;

  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;

  -webkit-transform: skewX(30deg);
  -ms-transform: skewX(30deg);
  transform: skewX(30deg);
  transition: 1s;
}
.breadcrumb-custom .col.active:before {
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  border-right: 13px solid rgb(107, 190, 255);
}
.load_ls_overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff9c;
  z-index: 99;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
}
.Toastify__toast-container {
  z-index: 99999999 !important;
}
.Toastify__toast {
  border-radius: 10px !important;
  z-index: 99999999;
}
.Toastify__toast-body {
  font-weight: 800 !important;
}
.grecaptcha-badge {
  opacity: 0;
}

.alert {
  font-weight: 600;
}
table.table td {
  vertical-align: middle;
}

.custom-table tr:hover td {
  transition: 200ms;
  background-color: #e8e8e8;
}
.modal-body {
  background: #f7f7f7;
}
.modal-footer {
  background: $body;
  border-top: 0;
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.badge-warning {
  color: white;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(51, 51, 51, 0.05);
}
.table thead th {
  font-size: 12px;
}
.bg-body {
  background: #f3f3f3 !important;
}
.custom-card {
  padding: 2rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  background: white;
  border-radius: 8px;
}

.ep-date {
  transition: 100ms;
  box-shadow: none;
  &:hover {
    transform: scale(1.02) translateY(-4px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #ddd;
}
.vertical-timeline-element-icon {
  margin-left: 4px;
  margin-top: 8px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05), 0 0 15px -3px #afafaf;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 128%;
    top: 13px;
    font-size: 12px;
  }
  .vertical-timeline-element-icon {
    margin-left: -16px !important;
    margin-top: 16px;
  }
  .vertical-timeline--one-column .vertical-timeline-element-icon {
    margin-left: 4px !important;
    margin-top: 8px;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content
    .vertical-timeline-element-date,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    left: auto;
    right: 127%;
    text-align: right;
  }
  .vertical-timeline-element {
    margin: 2em 0;
  }
}
