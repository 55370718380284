#form_container {
  transition: 500ms;
}
.question_container {
  position: absolute;
  width: 261px;
  margin-top: 43px;
}
.question_container.hidden #Form_counter {
  color: #2497f3;
  margin-bottom: 0;
  font-size: 12px;
}
.question_title {
  color: #2497f3;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.question_description {
  font-size: 12px;
}
.question_answer_container {
  margin-top: 24px;
}
.question_answer_container .btn-group {
  display: flex;
  flex-direction: column;
}
.question_answer_container .btn-group button {
  min-height: 35px;
  margin-right: 0;
  margin-bottom: 1px;

  padding: 3px 10px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: none;
  border-radius: 5px !important;
}
.question_answer_container .btn.active {
  background: #349ff5;
  color: white;
}
.question_answer_container .btn.active .fa {
  line-height: 13px;
  color: #ffffff;
  padding: 7px 9px;
  border-radius: 100%;

  font-size: 10px;
  float: right;
}

.question_answer_container .form-group {
  margin-bottom: 0;
}
.btn_nav.btn_nav_next {
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 0;
}
.btn_nav.btn_nav_prev {
  background: white;
  border-radius: 8px;
  border: 0;
  width: 110px;
  margin-right: 5px;
  font-size: 14px;
}
.question_navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.hidden {
  opacity: 0;
  transform: translateX(-100%);
  display: none;
}
