.form-group {
  padding: 7px;
  border-radius: 5px;
}
#form_container {
  min-height: 600px;
}
label {
  font-weight: 600;
  color: theme-color("primary");
  margin-bottom: 3px;
}
.input-group {
  background: #fbfafa;
  border-radius: 5px;
}
.input-group .input-group-append {
  border: 0px solid #dddddd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  //box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.input-group-append .btn,
.input-group-prepend .btn {
  height: 36px;
  line-height: 11px;
}

.form-control {
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  padding: 0 9px !important;
  height: 36px;
  border: 1px solid #dddddd;
  box-shadow: none !important;
  //box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

  &:focus {
    border: 1px solid #bad3e6;
  }
}

.form-control:disabled,
.form-control[readonly] {
  //background-color: #b7b7b700;
  color: black !important;
  opacity: 1;
  background: #ececec;
  box-shadow: none !important;
}

textarea[disabled],
textarea[disabled]::placeholder,
textarea.form-control[disabled],
textarea.form-control[disabled]::placeholder,
input.form-control[disabled],
input.form-control[disabled]::placeholder,
input[type="text"][disabled],
input[type="text"][disabled]::placeholder,
input[type="password"][disabled],
input[type="password"][disabled]::placeholder,
input[type="email"][disabled],
input[type="email"][disabled]::placeholder,
input[type="number"][disabled],
input[type="number"][disabled]::placeholder,
[type="text"].form-control[disabled],
[type="text"].form-control[disabled]::placeholder,
[type="password"].form-control[disabled],
[type="password"].form-control[disabled]::placeholder,
[type="email"].form-control[disabled],
[type="email"].form-control[disabled]::placeholder,
[type="tel"].form-control[disabled],
[type="tel"].form-control[disabled]::placeholder,
[contenteditable].form-control[disabled],
[contenteditable].form-control[disabled]::placeholder {
  color: #9c9c9c;
}
