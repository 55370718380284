h1 {
  margin-bottom: 0;
}
h2,
.h2 {
  color: #bdbdbd;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  font-weight: 800;
}
