@import "./colors.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "./buttons.scss";
@import "./inputs.scss";
@import "./titles.scss";
@import "./misc.scss";

html {
  //filter: invert(1) hue-rotate(180deg);
}
html,
body,
#root {
  min-height: 100%;
  font-size: 15px;
  background: $body;
  //background: linear-gradient(#004e92, #000428);
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.navbar {
  background: #0e6bb0 linear-gradient(180deg, #2588d2, #0e6bb0) repeat-x;
}
.navbar-brand {
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.cursor-pointer {
  cursor: pointer;
}
.rounded {
  border-radius: 8px !important;
}

:export {
  primary: theme-color("primary");
  success: theme-color("success");
  info: theme-color("info");
  danger: theme-color("danger");
  warning: theme-color("warning");
  dark: theme-color("dark");
  secondary: theme-color("secondary");
  light: theme-color("light");
  body: $body;
}
